<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col">
        <v-card class="py-32 px-16 p-xl-32">
          <div class="row">
            <div class="col">
              <h3 class="mb-32">Тип 1</h3>

              <drag-ranging
                :variants="variant"
              ></drag-ranging>
            </div>
          </div>

          <hr class="my-40 mx-n16 mx-xl-n32">

          <div class="row mt-16">
            <div class="col">
              <h3 class="mb-32">Тип 2</h3>

              <drag-ranging-multiple
                :variants="variant"
                :groups="rank_variant"
              ></drag-ranging-multiple>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import VCard from '@/components/base/VCard'
import {
  DragRanging,
  DragRangingMultiple
} from '@/components/features/DragRaging'

export default {
  name: 'SDragAndDrop',

  components: {
    DragRanging,
    DragRangingMultiple,
    VCard
  },

  data () {
    return {
      variant: [
        { id: 1, text: 'Человек' },
        { id: 2, text: 'Одуванчик' },
        {
          id: 3,
          text: 'Много текста Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deserunt dolor dolore dolores et placeat provident temporibus voluptatum. Ad consequuntur delectus, distinctio explicabo modi obcaecati officiis sapiente sunt suscipit tenetur voluptates.'
        },
        { id: 4, text: 'Шампиньон' },
        { id: 5, text: 'Коронавирус' },
        { id: 6, text: 'Кот' },
        { id: 7, text: 'Ромашка' },
        { id: 8, text: 'Подберезовик' },
        { id: 9, text: 'Эбола' }
      ],
      rank_variant: [
        { id: 1, text: 'Животные' },
        { id: 2, text: 'Растения' },
        { id: 2, text: 'Грибы' },
        { id: 2, text: 'Вирусы' }
      ]
    }
  }
}
</script>
